import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { lazy } from "react";

// project imports
import AdminLayout from "../components/layouts/AdminLayout";
import Loadable from "../components/loadable/Loadable";

const Profile = Loadable(lazy(() => import("../views/profile/profile")));

// Dashboard
const Sector = Loadable(lazy(() => import("../modules/dashboard/Sector")));
const SectorDetail = Loadable(lazy(() => import("../modules/dashboard/SectorDetail")));
const Establishment = Loadable(lazy(() => import("../modules/dashboard/Establishment")));
const EstablishmentDetail = Loadable(lazy(() => import("../modules/dashboard/EstablishmentDetail")));
const Fertilization = Loadable(lazy(() => import("../modules/dashboard/Fertilization")));
const Yield = Loadable(lazy(() => import("../modules/dashboard/Yield")));
const DashboardMap = Loadable(lazy(() => import("../views/dashboard/DashboardMap")));

// Disputes routing
const Production = Loadable(lazy(() => import("../views/production/Production")));
const NewProduction = Loadable(lazy(() => import("../views/production/NewProduction")));
const ProductionDetail = Loadable(lazy(() => import("../views/production/ProductionDetail")));

// Sinisana
// const Account = Loadable(lazy(() => import("../views/settings/account/Account")));
// const AccountDetail = Loadable(lazy(() => import("../views/settings/account/AccountDetail")));
const User = Loadable(lazy(() => import("../modules/user/User")));
const UserDetail = Loadable(lazy(() => import("../modules/user/UserDetail")));
const Entity = Loadable(lazy(() => import("../modules/entity/Entity")));
const PlotCycle = Loadable(lazy(() => import("../modules/plotCycle/PlotCycle")));
const Role = Loadable(lazy(() => import("../modules/role/Role")));
const RoleDetail = Loadable(lazy(() => import("../modules/role/RoleDetail")));
const Feedback = Loadable(lazy(() => import("../modules/feedback/Feedback")));
const Plot = Loadable(lazy(() => import("../modules/plot/Plot")));
const Planting = Loadable(lazy(() => import("../modules/planting/Planting")));
const Harvest = Loadable(lazy(() => import("../modules/harvest/Harvest")));
const Treatment = Loadable(lazy(() => import("../modules/treatment/Treatment")));
const UserFertilization = Loadable(lazy(() => import("../modules/fertilization/Fertilization")));
const Mission = Loadable(lazy(() => import("../modules/mission/Mission")));
const Notification = Loadable(lazy(() => import("../modules/notification/Notification")));

const PermissionDenied = () => {
  return (
    <Grid container padding={{ xs: 0, md: 2 }} marginTop={{ md: "24px" }} spacing={2}>
      <Grid item xs={12}>
        <Box>
          <h1>Permission Denied</h1>
          <p>You don't have permission to access this page. Please contact your administrator.</p>
        </Box>
      </Grid>
    </Grid>
  );
};

// ==============================|| MAIN ROUTING ||============================== //
const AdminRoutes = (can) => {
  return {
    path: "/admin",
    element: <AdminLayout />,
    children: [
      {
        path: "profile",
        element: <Profile />,
      },
      {
        path: "dashboard/establishments",
        element: <Establishment />,
      },
      {
        path: "dashboard/establishments/:id",
        element: <EstablishmentDetail />,
      },
      {
        path: "dashboard/sectors",
        element: <Sector />,
      },
      {
        path: "dashboard/sectors/:name",
        element: <SectorDetail />,
      },
      {
        path: "dashboard/fertilization",
        element: <Fertilization />,
      },
      {
        path: "dashboard/yield",
        element: <Yield />,
      },
      {
        path: "dashboard/map",
        element: can("DashboardPage") ? <DashboardMap /> : <PermissionDenied />,
      },
      {
        path: "/admin/manage/productions",
        element: can("Dashboard") ? <Production /> : <PermissionDenied />,
      },
      {
        path: "/admin/manage/productions/new",
        element: can("Dashboard") ? <NewProduction /> : <PermissionDenied />,
      },
      {
        path: "/admin/manage/productions/:id",
        element: can("Dashboard") ? <ProductionDetail /> : <PermissionDenied />,
      },
      {
        path: "/admin/users",
        element: can("User.View") ? <User /> : <PermissionDenied />,
      },
      {
        path: "/admin/users/:id",
        element: can("User.View") ? <UserDetail /> : <PermissionDenied />,
      },
      {
        path: "/admin/entities",
        element: can("Entity.View") ? <Entity /> : <PermissionDenied />,
      },
      {
        path: "/admin/plotCycles",
        element: <PlotCycle />,
      },
      {
        path: "/admin/roles",
        element: can("Role.View") ? <Role /> : <PermissionDenied />,
      },
      {
        path: "/admin/roles/:id",
        element: can("Role.View") ? <RoleDetail /> : <PermissionDenied />,
      },
      {
        path: "/admin/feedback",
        element: can("Feedback.View") ? <Feedback /> : <PermissionDenied />,
      },
      {
        path: "/admin/planting",
        element: can("Planting.View") ? <Planting /> : <PermissionDenied />,
      },
      {
        path: "/admin/harvest",
        element: can("Harvest.View") ? <Harvest /> : <PermissionDenied />,
      },
      {
        path: "/admin/treatment",
        element: can("Treatment.View") ? <Treatment /> : <PermissionDenied />,
      },
      {
        path: "/admin/fertilization",
        element: can("Fertilization.View") ? <UserFertilization /> : <PermissionDenied />,
      },
      {
        path: "/admin/mission",
        element: can("Mission.View") ? <Mission /> : <PermissionDenied />,
      },
      {
        path: "/admin/notification",
        element: can("Notification.View") ? <Notification /> : <PermissionDenied />,
      },
      {
        path: "plot",
        element: can("Plot.View") ? <Plot /> : <PermissionDenied />,
      },
    ],
  };
};

export default AdminRoutes;
